<template>
  <MainPanel>
    <a-row :gutter="24" style="margin-top: 20px;margin-left: 20px">
      <a-col :span="24">
        <a-form-item label="">
          <a-avatar :src="user.avatar" size="large" style="color: #f56a00; background-color: #fde3cf">
            <template #icon><UserOutlined /></template>
          </a-avatar>
          <span style="margin-left: 20px;font-size: large;">
            <b>{{ user.nickname }}</b>
          </span>

        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item label="手机号">
          {{ user.phone }}
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="邮箱">
          {{ user.mail }}
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="用户名">
          {{ user.username }}
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item label="姓名">
          {{ user.realname }}
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item label="性别">
          <div v-if="user.gender === 1">
            男
          </div>
          <div v-else-if="user.gender === 0">
            女
          </div>
          <div v-else>

          </div>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item label="创建时间">
          {{ user.createTime }}
        </a-form-item>
      </a-col>

    </a-row>

    <a-table :dataSource="user.companyList" :columns="companyColumns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'isLock'">
          <span>
            <a-tag v-if="record.isLock === 1" color="red">
              是
            </a-tag>
            <a-tag v-else-if="record.isLock === 0" color="green">
              否
            </a-tag>
            <a-tag v-else>
              {{ record.isLock }}
            </a-tag>
          </span>
        </template>
      </template>
    </a-table>

    <a-table :dataSource="user.roleList" :columns="roleColumns" :pagination="false">
    </a-table>

  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";
import * as UserApi from '@/api/UserApi.js';
import {message} from "ant-design-vue";

export default {
  name: 'UserCenter',
  components: {
    MainPanel
  },
  data() {
    return {
      user: {
        phone: '',
        mail: '',
        username: '',
        createTime: '',
        realname: '',
        nickname: '',
        avatar: '',
        gender: '',
        companyList: [],
        roleList: [],
      },

      companyColumns: [
        {
          title: '公司名称',
          dataIndex: 'companyName',
          key: 'companyName',
          align: 'center',
        },
        {
          title: '是否锁定',
          dataIndex: 'isLock',
          key: 'isLock',
          align: 'center',
        },
        {
          title: '优先级',
          dataIndex: 'priority',
          key: 'priority',
          align: 'center',
        },
      ],

      roleColumns: [
        {
          title: '公司名称',
          dataIndex: 'companyName',
          key: 'companyName',
          align: 'center',
        },
        {
          title: '部门名称',
          dataIndex: 'deptName',
          key: 'deptName',
          align: 'center',
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key: 'roleName',
          align: 'center',
        },
        {
          title: '优先级',
          dataIndex: 'priority',
          key: 'priority',
          align: 'center',
        },

      ],


    }
  },

  methods: {
    findUserCenter() {
      UserApi['findUserCenterApi']()
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        if (code === 200) {
          this.user = result.data.data;
        } else {
          message.error(msg);
        }

      })
      .catch((e) => {
        message.error(e.message);
      })
    }
  },

  created() {
    this.findUserCenter();
  },

  mounted() {

  }
}
</script>
